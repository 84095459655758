"use strict"; 
var body = document.getElementsByTagName("BODY")[0];
var selector = '[data-dashboard-charts="true"]'
   
// Initialize Menus
var elements = body.querySelectorAll(selector);    
if (elements && elements.length > 0) {
  var monthLabelsList = monthLabels;
  var downloadCountsList = downloadCounts;
  var activeCountsList = activeCounts;
  var activeCountPercentage = activePercentages;
  
  function calculateChartMaxPoint(arr){
    let maxPoint = Math.max(...arr);
    return maxPoint+50;
  };

  var options = {    
    series: [{
      name: '登録ユーザー',
      type: 'column',
      data: downloadCountsList
    }, {
      name: 'アクティブユーザー数 ',
      type: 'column',
      data: activeCountsList
    }, {
      name: 'アクティブ ユーザー率 ',
      type: 'line',
      data: activeCountPercentage
    }],
      chart: {
      height: 350,
      type: 'line',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },    
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [1, 1, 2]
    },
    title: {
      text: '',
      align: 'left',
      offsetX: 110
    },
    xaxis: {
      categories: monthLabelsList,
    },
    yaxis: [   
      {      
        min: 0,
        max: calculateChartMaxPoint(downloadCountsList),
        tickAmount: 10,
      
        axisTicks: {
          show: true,        
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#008FFB',
          }
        },
        title: {
          text: "登録ユーザー",
          style: {
            color: '#008FFB',
          }
        },
        tooltip: {
          enabled: true
        }
      },
      {
        seriesName: 'アクティブユーザー数',
        opposite: true,
        min: 0,
        max: calculateChartMaxPoint(activeCountsList),
        tickAmount: 10,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#00E396'
        },
        labels: {
          style: {
            colors: '#00E396',
          }
        },
        title: {
          text: "アクティブユーザー数",
          style: {
            color: '#00E396',
          }
        },
      },
      {
        seriesName: 'アクティブ ユーザー率',
        opposite: true,
        min: 0,
        max: calculateChartMaxPoint(activeCountPercentage),
        tickAmount: 10,
        axisTicks: {
          show: true,        
        },
        axisBorder: {
          show: true,
          color: '#FEB019'
        },
        labels: {
          style: {
            colors: '#FEB019',
          },
        },
        title: {
          text: "アクティブ ユーザー率",
          style: {
            color: '#FEB019',
          }
        }
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40
    }    
  };
  var chart = new ApexCharts(document.querySelector("#chart"), options);
  chart.render();
}