import { Controller } from "@hotwired/stimulus";
import Cropper from "cropperjs";

// Connects to data-controller="cropper"
export default class extends Controller {
  connect() {
    this.imageCrop();
  }

  imageCrop = function () {
    var cropper;
    var preview = $(".upload-preview img");
    var defaultProfilePreview = $(".default-profile-upload-preview img");

    /* input file change action */
    $(".input-profile-image-file").on("change", function (e) {
      $("#imageFlag").val(false);
      var files = e.target.files;
      if (files && files.length > 0) {
        var reader = new FileReader();
        reader.onload = function (event) {
          var img = document.getElementById("cropper-image-demo");
          img.onload = function () {
            cropper = new Cropper(img, {
              aspectRatio: 1 / 1,
              viewMode: 1,
              autoCrop: true,
            });
          };
          img.src = event.target.result;
        };
        reader.readAsDataURL(this.files[0]);
        $("#uploadimageModal").show();
      }
    });

    /* profile image crop action */
    $(".crop-profile-image").click(function () {
      var upload_img_name = $(".input-profile-image-file")[0].value.replace(
        /^.*[\\\/]/,
        ""
      );
      var croppedImageDataURL = cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg");
      $("#image_data").val(upload_img_name + "," + croppedImageDataURL);
      preview.attr("src", croppedImageDataURL);
      $("#uploadimageModal").hide();

      if ($("#image_data").val() != "false") {
        $(".chosen-text").text(upload_img_name);
        $("#img-remove").show();
        $(".input-profile-image-file")[0].value = ""; //for onChange not fire if select the same file
      } else {
        $(".chosen-text").text("写真をアップロードしてください。");
        $("#img-remove").hide();
      }
      cropper.destroy();
    });

    /*close modal*/
    $(".close-crop-profile-image").click(function () {
      $("#uploadimageModal").hide();
      $(".input-profile-image-file")[0].value = "";
      cropper.destroy();
    });

    /*image rotate right action*/
    $(".rotate-right").click(function () {
      cropper.rotate(90);
    });

    /*image rotate left action*/
    $(".rotate-left").click(function () {
      cropper.rotate(-90);
    });

    /*image flip horizontal action*/
    $(".flip-horizontal").click(function () {
      if (
        cropper.getImageData().rotate == 0 ||
        cropper.getImageData().rotate == 180
      ) {
        if (cropper.getImageData().scaleX == 1)
          cropper.getImageData().scaleY == 1
            ? cropper.scale(-1, 1)
            : cropper.scale(-1, -1);
        else if (cropper.getImageData().scaleX == -1)
          cropper.getImageData().scaleY == 1
            ? cropper.scale(1, 1)
            : cropper.scale(1, -1);
      } else {
        if (cropper.getImageData().scaleX == 1)
          cropper.getImageData().scaleY == 1
            ? cropper.scale(1, -1)
            : cropper.scale(1, 1);
        else if (cropper.getImageData().scaleX == -1)
          cropper.getImageData().scaleY == 1
            ? cropper.scale(1, -1)
            : cropper.scale(-1, 1);
      }
    });

    /*image flip vertical action*/
    $(".flip-vertical").click(function () {
      if (
        cropper.getImageData().rotate == 0 ||
        cropper.getImageData().rotate == 180
      ) {
        if (cropper.getImageData().scaleY == 1)
          cropper.getImageData().scaleX == 1
            ? cropper.scale(1, -1)
            : cropper.scale(-1, -1);
        else if (cropper.getImageData().scaleY == -1)
          cropper.getImageData().scaleX == 1
            ? cropper.scale(1, 1)
            : cropper.scale(-1, 1);
      } else {
        if (cropper.getImageData().scaleY == 1)
          cropper.getImageData().scaleX == 1
            ? cropper.scale(-1, 1)
            : cropper.scale(1, 1);
        else if (cropper.getImageData().scaleY == -1)
          cropper.getImageData().scaleX == 1
            ? cropper.scale(-1, -1)
            : cropper.scale(1, -1);
      }
    });

    /*image restore initial state action*/
    $(".restore").click(function () {
      cropper.reset();
    });

    /* to remove/delete image file */
    $("#img-remove").click(function () {
      preview.attr("src", "/assets/avatar/male.svg");
      $("#image").val("");
      $("#imageFlag").val(true);
      $("#image_data").val(false);
      $("#img-remove").hide();
      $(".chosen-text").text("写真をアップロードしてください。");
    });

    /***********************************************************************
     * Cover Image Function
     ************************************************************************/
    /*load image file to modal*/
    $(".input-cover-image-file").on("change", function (e) {
      $("#imageFlag").val(false);
      var files = e.target.files;
      if (!files[0].type.startsWith("image/")) {
        $(".image-error").text("Please select a valid image file.");
        $(".input-cover-image-file").val("");
        return;
      }
      var fileSize = 2 * 1024 * 1024;
      // if (files[0].size > fileSize) {
      //   $(".image-error").text(
      //     "アップロードしている一つの写真が２MBを超えております"
      //   );
      //   $(".input-cover-image-file").val("");
      //   return;
      // }
      if (files && files.length > 0) {
        var reader = new FileReader();
        reader.onload = function (event) {
          var img = document.getElementById("cropper-image-demo");
          img.onload = function () {
            cropper = new Cropper(img, {
              aspectRatio: 16 / 9,
              viewMode: 1,
              autoCrop: true,
            });
          };
          img.src = event.target.result;
        };
        $(".image-error").text("");
        reader.readAsDataURL(this.files[0]);
        $("#uploadimageModal").show();
      }
    });

    /*change picture action*/
    $(".change-cover-image").click(function () {
      $(".input-cover-image-file").trigger("click");
      $(".input-cover-image-file").on("change", function () {
        $(".input-cover-image-file")[0].value = "";
        cropper.destroy();
      });
    });

    /* cover image crop action */
    $(".crop-cover-image").click(function () {
      var upload_img_name = $(".input-cover-image-file")[0].value.replace(
        /^.*[\\\/]/,
        ""
      );
      var croppedImageDataURL = cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg");
      $("#image_data").val(upload_img_name + "," + croppedImageDataURL);
      preview.attr("src", croppedImageDataURL);
      $("#uploadimageModal").hide();
      $("#old_image").remove();

      if ($("#image_data").val() != "false") {
        $(".chosen-text").text(upload_img_name);
        $("#img-remove").show();
        $(".input-cover-image-file")[0].value = ""; //for onChange not fire if select the same file
      } else {
        $(".chosen-text").text("写真をアップロードしてください。");
        $("#img-remove").hide();
      }
      cropper.destroy();
    });

    /*close modal*/
    $(".close-crop-cover-image").click(function () {
      $("#uploadimageModal").hide();
      $(".input-cover-image-file")[0].value = "";
      cropper.destroy();
    });

    /* to remove/delete cover image file */
    $("#cover-img-remove").click(function () {
      preview.attr("src", "/assets/avatar/preview.svg");
      $("#image").val("");
      $("#imageFlag").val(true);
      $("#image_data").val(false);
      $("#img-remove").hide();
      $("#old_image").remove();
      $(".chosen-text").text("写真をアップロードしてください。");
    });

    // /***********************************************************************
    //  * Default Profile Image Function
    //  ************************************************************************/
    // /*load image file to modal*/
    $(".input-default-profile-image-file").on("change", function (e) {
      $("#defaultProfileImageFlag").val(false);
      var files = e.target.files;
      if (!files[0].type.startsWith("image/")) {
        $(".profile-error").text("Please select a valid image file.");
        $(".input-default-profile-image-file").val("");
        return;
      }
      var fileSize = 2 * 1024 * 1024;
      // if (files[0].size > fileSize) {
      //   $(".profile-error").text(
      //     "アップロードしている一つの写真が２MBを超えております"
      //   );
      //   $(".input-default-profile-image-file").val("");
      //   return;
      // }
      if (files && files.length > 0) {
        var reader = new FileReader();
        reader.onload = function (event) {
          var img = document.getElementById(
            "cropper-default-profile-image-demo"
          );
          img.onload = function () {
            cropper = new Cropper(img, {
              aspectRatio: 1 / 1,
              viewMode: 1,
              autoCrop: true,
            });
          };
          img.src = event.target.result;
        };
        $(".profile-error").text("");
        reader.readAsDataURL(this.files[0]);
        $("#uploadDefaultProfileModal").show();
      }
    });

    /*change picture action*/
    $(".change-cover-image").click(function () {
      $(".input-default-profile-image-file").trigger("click");
      $(".input-default-profile-image-file").on("change", function () {
        $(".input-default-profile-image-file")[0].value = "";
        cropper.destroy();
      });
    });

    /* cover image crop action */
    $(".crop-default-profile").click(function () {
      var upload_img_name = $(
        ".input-default-profile-image-file"
      )[0].value.replace(/^.*[\\\/]/, "");
      var croppedImageDataURL = cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg");
      $("#default_profile_image_data").val(
        upload_img_name + "," + croppedImageDataURL
      );
      defaultProfilePreview.attr("src", croppedImageDataURL);
      $("#uploadDefaultProfileModal").hide();
      $("#old_default_profile").remove();

      if ($("#default_profile_image_data").val() != "false") {
        $(".default-profile-chosen-text").text(upload_img_name);
        $("#default-profile-img-remove").show();
        $(".input-default-profile-image-file")[0].value = ""; //for onChange not fire if select the same file
      } else {
        $(".default-profile-chosen-text").text(
          "写真をアップロードしてください。"
        );
        $("#default-profile-img-remove").hide();
      }
      cropper.destroy();
    });

    /*close modal*/
    $(".close-crop-default-profile").click(function () {
      $("#uploadDefaultProfileModal").hide();
      $(".input-default-profile-image-file")[0].value = "";
      cropper.destroy();
    });

    /* to remove/delete cover image file */
    $("#default-profile-img-remove").click(function () {
      defaultProfilePreview.attr("src", "/assets/avatar/preview.svg");
      $("#default_profile").val("");
      $("#defaultProfileImageFlag").val(true);
      $("#default_profile_image_data").val(false);
      $("#default-profile-img-remove").hide();
      $("#old_default_profile").remove();
      $(".chosen-text").text("写真をアップロードしてください。");
    });
  };
}
