import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-toggle"
export default class extends Controller {
  connect() {
  }

  toggleCrtPasswordEmail() {
    var crt_password_field_email = $("#ctr-pw-email");
    var icon = $("#crt-pw-icon-email");
    this.toggleClass(crt_password_field_email, icon);
  }

  toggleCrtPasswordPassword() {
    var crt_password_field_pw = $("#crt-pw-pw");
    var icon = $("#crt-pw-icon-pw");
    this.toggleClass(crt_password_field_pw, icon);
  }

  toggleNewPasswordPassword() {
    var new_password_field = $("#new-pw-pw");
    var icon = $("#new-pw-icon-pw");
    this.toggleClass(new_password_field, icon);
  }

  toggleConfirmPasswordPassword() {
    var conf_password_field = $("#conf-pw-pw");
    var icon = $("#conf-pw-icon-pw");
    this.toggleClass(conf_password_field, icon);
  }

  toggleClass(pwField,iconElement) {
    if (pwField.attr('type') == "password") {
      iconElement.removeClass('bi-eye-slash');
      iconElement.addClass('bi-eye');
      pwField.attr('type', 'text');
    } else {
      iconElement.removeClass('bi-eye');
      iconElement.addClass('bi-eye-slash');
      pwField.attr('type', 'password');
    }
  }
}
