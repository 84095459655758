import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="group-point-time-frame"
export default class extends Controller {
  static targets = [
    "checkbox",
    "get_point",
    "add_event_item",
    "event_template",
  ];

  connect() {
    this.toggleCheckbox();
  }

  toggleCheckbox() {
    if (this.hasCheckboxTarget) {
      if (this.checkboxTarget.checked) {
        this.get_pointTarget.classList.remove("d-none");
      } else {
        this.get_pointTarget.classList.add("d-none");
      }
    }
  }

  add_event_association(event) {
    event.preventDefault();
    var content = this.event_templateTarget.innerHTML.replace(
      /EVENT_TEMPLATE_RECORD/g,
      new Date().getTime()
    );
    this.add_event_itemTarget.insertAdjacentHTML("beforebegin", content);
  }

  remove_group_point_info_association(event) {
    event.preventDefault();
    let item = event.target.closest(".group-point-nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
  }

  remove_group_point_event_association(event) {
    event.preventDefault();
    let item = event.target.closest(".group-point-event-nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
  }
}
