import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "source" ]

  copy() {
    this.copyQR();
  }

  copyQR = async () => {
    try {
      const org_code = $("#code_to_copy").text();
      const name = $("#name_to_copy").text();
      const imgURL = this.sourceTarget.src;
      const imageData = await fetch(imgURL);
      const imageBlob = await imageData.blob();
      const imageBase64Data = await this.blobToBase64(imageBlob);
      const html =
        `<p><img src="${imageBase64Data}" alt="QR表示"></p>
        <p>${org_code}</p>
        <p>${name}</p>`;
      const textBlob = new Blob([html], {
        type: 'text/html'
      });
      await navigator.clipboard.write([
        new ClipboardItem({
          [textBlob.type]: textBlob
        })
      ]);
      console.log('QR Code, Org Code and Name Copied.');
    } catch (err) {
      console.error(err.name, err.message);
    }
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
