import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];
  static classes = ["active"];
  connect() {
    this.servSubscriberPaymentMethodChange();
  }

  itemClick(event) {
    var selectedId = event.params.id;
    this.itemTargets.forEach((element, index) => {
      if (element.dataset.servSubscriberIdParam == selectedId) {
        element.classList.add(this.activeClass);
      } else {
        element.classList.remove(this.activeClass);
      }
    });
  }

  servSubscriberPaymentMethodChange(e) {
    if ($("#serv_subscriber_bank_info_payment_method_cash").is(":checked")) {
      $(".bank-info-input").addClass("d-none");
    } else {
      $(".bank-info-input").removeClass("d-none");
    }
  }

  saveBankInfo() {
    if ($("#serv_subscriber_bank_info_payment_method_cash").is(":checked")) {
      $("#bank_name").val("");
      $("#branch").val("");
      $("#serv_subscriber_bank_info_acc_type_ordinary_deposit").prop("checked", true);
      $("#acc_name").val("");
      $("#acc_no").val("");
    }
  }
}
