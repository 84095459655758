import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
export default class extends Controller {
  static targets = ["item"];
  static classes = ["active"];

  connect() {
    $("#additional_charge").select2();
    $("#additional_charge").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    $("#payment_period").select2();
    $("#payment_period").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    $("#tax_classification").select2();
    $("#tax_classification").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    $("#payment_date").select2();
    $("#payment_date").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    $("#payment_term").select2();
    $("#payment_term").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    this.additional_charge();
    this.payment_period();
    this.tax_type();
  }

  itemClick(event) {
    var selectedId = event.params.id;
    this.itemTargets.forEach((element, index) => {
      if (element.dataset.mFeeIdParam == selectedId) {
        element.classList.add(this.activeClass);
      } else {
        element.classList.remove(this.activeClass);
      }
    });
  }

  additional_charge() {
    if ($("#additional_charge option:selected").val() == "with_person") {
      $(".additional-charge-sec").removeClass("d-none");
    } else {
      $(".applicable-user-from").val("");
      $(".applicable-user-to").val("");
      $(".additional-charge-sec").addClass("d-none");
    }
  }
  payment_period() {
    if ($("#payment_period option:selected").val() == "event_participation") {
      $(".event-code").removeClass("d-none");
    } else {
      $(".event-code").val("");
      $(".event-code").addClass("d-none");
    }
  }

  tax_type() {
    if ($("#tax_classification").val() == "tax_included") {
      $(".tax-rate-sec").removeClass("d-none");
    } else {
      $(".tax-rate").val("");
      $(".tax-rate-sec").addClass("d-none");
    }
  }
}
