import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="app-user-search"
export default class extends Controller {
    connect() {
        $("#provider_select").select2();
        $('#provider_select').on("select2:select", function (e) {
            let event = new Event("change", { bubbles: true }); // fire a native event
            this.dispatchEvent(event);
        });

        $("#subscriber_select").select2();
        $('#subscriber_select').on("select2:select", function (e) {
            let event = new Event("change", { bubbles: true }); // fire a native event
            this.dispatchEvent(event);
        });

        $("#group_select").select2();
        $('#group_select').on("select2:select", function (e) {
            let event = new Event("change", { bubbles: true }); // fire a native event
            this.dispatchEvent(event);
        });

        this.setDynamicSelect();
    }

    providerSelect(e) {
        var data = e.target.value;
        
        $.ajax({
            url: "/get_subscribers",
            data: {
                sel_provider_id: data
            },
            type: "GET",
            dataType: "json",
            success: function (data) {
                if (data) {
                    $('#subscriber_select').find('option:not(:first)').remove();
                    $.each(data.subscriber_list, function (i, e) {
                        $('#subscriber_select').append(`<option value=${e[1]}>` + e[0] + '</option>');
                    });
                } 
                if ($('#subscriber_select option').length > 1) {
                    $(".subscriber_select").removeClass("d-none");
                    if (!$(".group_select").hasClass("d-none")) {
                        $(".group_select").addClass("d-none");
                    }
                }
                else {
                    $(".subscriber_select").addClass("d-none");
                    $(".group_select").addClass("d-none");
                }
            },
            error: function (error) { },
        });
    }

    subscriberSelect(e) {
        var data = e.target.value;
        
        $.ajax({
            url: "/get_groups",
            data: {
                sel_subscriber_id: data
            },
            type: "GET",
            dataType: "json",
            success: function (data) {
                if (data) {
                    $('#group_select').find('option:not(:first)').remove();
                    $.each(data.group_list, function (i, e) {
                        $('#group_select').append(`<option value=${e[1]}>` + e[0] + '</option>');
                    });
                } 
                if ($('#group_select option').length > 1) {
                    $(".group_select").removeClass("d-none");
                }
                else {
                    $(".group_select").addClass("d-none");
                }
            },
            error: function (error) { },
        });
    }

    setDynamicSelect() {
        var selProvider = $("#provider_select").val();
        var selSubscriber = $("#sel_subscriber").val();
        var selGroup = $("#sel_group").val(); 
        if (selProvider != "") {
            $(".subscriber_select").removeClass("d-none");
        } else {
            $(".subscriber_select").addClass("d-none");
        }
        if (selSubscriber != "") {
            $('#subscriber_select').find(`option[value=${selSubscriber}]`).attr('selected', 'selected');
            $(".group_select").removeClass("d-none");
            if (selGroup != "") {
                $('#group_select').find(`option[value=${selGroup}]`).attr('selected', 'selected');
            }
        } else {
            $(".group_select").addClass("d-none");
        }
    }
}
