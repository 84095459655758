import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="point-setting-details"
export default class extends Controller {
  static targets = ["item"];
  static classes = ["active"];

  connect() {}

  itemClick(event) {
    var selectedId = event.params.id;
    this.itemTargets.forEach((element, index) => {
      if (element.dataset.pointSettingDetailsIdParam == selectedId) {
        element.classList.add(this.activeClass);
      } else {
        element.classList.remove(this.activeClass);
      }
    });
  }

  newBtnClick() {
    if ($(".point-system_details-item").hasClass("active")) {
      $(".point-system_details-item").removeClass("active");
    }
  }
}
