import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="point-source"
export default class extends Controller {
  static targets = ["item"];
  static classes = ["active"];

  connect() {
    // $("#point_calc_meth").select2();
    // $("#point_calc_meth").on("select2:select", function () {
    //   let event = new Event("change", { bubbles: true }); // fire a native event
    //   this.dispatchEvent(event);
    // });
    // $("#data_input_type").select2();
    // $("#data_input_type").on("select2:select", function () {
    //   let event = new Event("change", { bubbles: true }); // fire a native event
    //   this.dispatchEvent(event);
    // });
  }

  itemClick(event) {
    var selectedId = event.params.id;
    this.itemTargets.forEach((element, index) => {
      if (element.dataset.pointSourceIdParam == selectedId) {
        element.classList.add(this.activeClass);
      } else {
        element.classList.remove(this.activeClass);
      }
    });
  }

  newBtnClick() {
    if ($(".point_source-item").hasClass("active")) {
      $(".point_source-item").removeClass("active");
    }
  }
}
