import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="m-point-setting"
export default class extends Controller {
  connect() {
    $("#data_input_type").on("select2:select", function (e) {
      let event = new Event("change", { bubbles: true });
      this.dispatchEvent(event);
    });

    const updValue = $("#point_source_id").val();

    const action_name = $("#act_name").val();

    if (action_name == "new" || action_name == "create") {
      this.field_change(updValue, 0);
    } else {
      this.field_change(updValue, 1);
    }
  }

  point_source_change(event) {
    const inputValue = event.target.value;

    this.field_change(inputValue, 0);
  }

  field_change(ch_val, act_value) {
    if (ch_val == 17) {
      $("#event_remove_block").fadeOut();
      $("#offer_point").val("1");
      $("#avg_value").val("1");
      $("#timing").val("daily");
    } else if (ch_val == 18) {
      $("#double_achi_remove_block").fadeOut();
      $("#event_remove_block").fadeIn();
      $("#avg_value").val("1");
    } else {
      $("#event_remove_block").fadeIn();
      $("#double_achi_remove_block").fadeIn();

      if (act_value == 0) {
        $("#offer_point").val("");
        $("#avg_value").val("");
        $("#timing").val("");
      }
    }
    $("#timing").trigger("change");
  }
}
