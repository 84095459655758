import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toastr"
export default class extends Controller {
  connect() {
    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: true,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "slideDown",
      hideMethod: "slideUp",
    };

    const message = this.element.dataset.message;
    const type = this.element.dataset.type;

    if (message) {
      if (type === "success") {
        toastr.success(message);
      } else if (type === "error") {
        toastr.error(message);
      }
    }
  }
}
