import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import $ from "jquery";

// Connects to data-controller="fee-enroll"
export default class extends Controller {
  static targets = ["startDate", "endDate"];

  connect() {
    this.date_picker();

    /* Fee ID Change Event */
    $(".fee-id").on("change", function (e) {
      var sel_subscriber_id = $("#serv_subscriber_id").val();
      var sel_personal_info_id = $("#personal_info_id").val();
      var sel_fee_id = $(this).val();
      if (sel_subscriber_id != "") {
        var url = "/serv_subscribers/" + sel_subscriber_id + "/serv_subscriber_bank_infos/fee_id_change";
      } else if (sel_personal_info_id != "") {
        var url = "/personal_info_masters/" + sel_personal_info_id + "/app_user_bank_infos/fee_id_change";
      }
      var idIndex = e.target.nextElementSibling.id;
      $.ajax({
        url: url,
        data: {
          sel_fee_id: sel_fee_id
        },
        type: "GET",
        dataType: "json",
        success: function (data) {
          document.cookie = "payment_period=" + data.payment_period + ';path=/';
          document.cookie = "payment_term=" + data.payment_term + ';path=/';
          var startDate = $("#start_date_" + idIndex);
          var endDate = $("#end_date_" + idIndex);
          const today = new Date()
          const yesterday = new Date(today)
          yesterday.setDate(yesterday.getDate() - 1)
          if (data.payment_term == "") {
            startDate.val('')
            startDate.siblings().val('')
            endDate.val('')
            endDate.siblings().val('')
          } else {
            switch (data.payment_term) {
              case "current_month":
                var newStartDate = today
                break;
              case "next_month":
                var newStartDate = new Date(today.getFullYear(), today.getMonth() + 1)
                break;
              case "month_after_next":
                var newStartDate = new Date(today.getFullYear(), today.getMonth() + 2)
                break;
              case "three_months_later":
                var newStartDate = new Date(today.getFullYear(), today.getMonth() + 3)
                break;
              case "four_months_later":
                var newStartDate = new Date(today.getFullYear(), today.getMonth() + 4)
                break;
              case "five_months_later":
                var newStartDate = new Date(today.getFullYear(), today.getMonth() + 5)
                break;
              case "six_months_later":
                var newStartDate = new Date(today.getFullYear(), today.getMonth() + 6)
                break;
              case "seven_months_later":
                var newStartDate = new Date(today.getFullYear(), today.getMonth() + 7)
                break;
              case "eight_months_later":
                var newStartDate = new Date(today.getFullYear(), today.getMonth() + 8)
                break;

              default:
                return;
            }
            var start_mm = newStartDate.getMonth() + 1;
            var selStartDateValue = newStartDate.getFullYear() + '-' + start_mm + '-01';
            var selStartDateJpFormat = newStartDate.getFullYear() + '年' + start_mm.toString().padStart(2, '0') + '月';
            if (data.payment_period == "" || data.payment_period == "event_participation") {
              startDate.val(selStartDateValue);
              startDate.siblings().val(selStartDateJpFormat);
              endDate.val('')
              endDate.siblings().val('')
            } else {
              switch (data.payment_period) {
                case "one_time_payment":
                  var newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth())
                  break;
                case "monthly":
                  var newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth())
                  break;
                case "yearly":
                  var newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 11)
                  break;
                case "three_months":
                  var newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 2)
                  break;
                case "six_months":
                  var newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 5)
                  break;

                default:
                  break;
              }
              var end_mm = newEndDate.getMonth() + 1;
              var selEndDateValue = newEndDate.getFullYear() + '-' + end_mm + '-01';
              var selEndDateJpFormat = newEndDate.getFullYear() + '年' + end_mm.toString().padStart(2, '0') + '月';
              startDate.val(selStartDateValue);
              startDate.siblings().val(selStartDateJpFormat);
              endDate.val(selEndDateValue)
              endDate.siblings().val(selEndDateJpFormat)
            }
          }
          if (data.payment_period == "" || data.payment_period == "event_participation") {
            document.cookie = "payment_period=; expires=" + yesterday.toDateString() + "; path=/;";
          }
          if (data.payment_term == "") {
            document.cookie = "payment_term=; expires=" + yesterday.toDateString() + "; path=/;";
          }
        },
        error: function (error) { },
      });
      e.stopImmediatePropagation();
      return false;
    });
  }

  date_picker() {
    var start_date_flatpickr = flatpickr(this.startDateTarget, {
      altInput: true,
      // allowInput: true,
      locale: Japanese,
      plugins: [
        new monthSelectPlugin({
          shorthand: true, //defaults to false
          dateFormat: "Y-m-d", //defaults to "F Y"
          altFormat: "Y年m月", //defaults to "F Y"
          theme: "light", // defaults to "light"
        }),
      ],
      onChange: function (selectedDates, dateStr, instance) {
        var idIndex = this.element.id.split("_")[2];
        const payment_period = ('; ' + document.cookie).split(`; payment_period=`).pop().split(';')[0];
        var startDate = new Date(dateStr)
        switch (payment_period) {
          case "one_time_payment":
            var newEndDate = new Date(startDate.getFullYear(), startDate.getMonth())
            break;
          case "monthly":
            var newEndDate = new Date(startDate.getFullYear(), startDate.getMonth())
            break;
          case "yearly":
            var newEndDate = new Date(startDate.getFullYear(), startDate.getMonth() + 11)
            break;
          case "three_months":
            var newEndDate = new Date(startDate.getFullYear(), startDate.getMonth() + 2)
            break;
          case "six_months":
            var newEndDate = new Date(startDate.getFullYear(), startDate.getMonth() + 5)
            break;

          default:
            return;
        }
        var endDate = $("#end_date_" + idIndex);
        var mm = newEndDate.getMonth() + 1;
        var selDateValue = newEndDate.getFullYear() + '-' + mm + '-01';
        var selDateJpFormat = newEndDate.getFullYear() + '年' + mm.toString().padStart(2, '0') + '月';
        endDate.val(selDateValue);
        endDate.siblings().val(selDateJpFormat);
      },
    });

    if (
      this.endDateTarget.value === "9999-09-09" ||
      this.endDateTarget.value === "9999/09/09"
    ) {
      this.endDateTarget.value = "9999/99";
      this.endDateTarget.readOnly = true;
    } else {
      this.endDateTarget.readOnly = false;
      var end_date_flatpickr = flatpickr(this.endDateTarget, {
        altInput: true,
        allowInput: true,
        locale: Japanese,
        plugins: [
          new monthSelectPlugin({
            shorthand: true, //defaults to false
            dateFormat: "Y-m-d", //defaults to "F Y"
            altFormat: "Y年m月", //defaults to "F Y"
            theme: "light", // defaults to "light"
          }),
        ],
        // onChange: function (selectedDates, dateStr, instance) {
        //   start_date_flatpickr.set("maxDate", dateStr);
        // },
      });
    }
  }

  unlimitedChange(e) {
    var isChecked = e.target.checked;
    var target = this.endDateTarget;
    var ef = flatpickr(target, {
      altInput: true,
      allowInput: true,
      locale: Japanese,
      plugins: [
        new monthSelectPlugin({
          shorthand: true, //defaults to false
          dateFormat: "Y-m-d", //defaults to "F Y"
          altFormat: "Y年m月", //defaults to "F Y"
          theme: "light", // defaults to "light"
        }),
      ],
      // onChange: function (selectedDates, dateStr, instance) {
      //   start_date.set("maxDate", dateStr);
      // },
    });

    if (isChecked == true) {
      ef.destroy();
      target.value = "9999/99";
      target.readOnly = true;
    } else {
      ef.setDate(new Date(), true, "Y-m");
      target.readOnly = false;
    }
  }
}
