// Bootstrap - The most popular framework uses as the foundation. Learn more: http://getbootstrap.com
window.bootstrap = require("bootstrap");
// Popper.js - Tooltip & Popover Positioning Engine used by Bootstrap. Learn more: https://popper.js.org
window.Popper = require("@popperjs/core");
// Apexcharts - mBdern charting library that helps developers to create beautiful and interactive visualizations for web pages: https://apexcharts.com/
window.ApexCharts = require("apexcharts/dist/apexcharts.min.js");
// SmothScroll
window.SmoothScroll = require("smooth-scroll");
// Sweetalert2
window.Swal = require("sweetalert2");
// Toastr
window.toastr = require("toastr");
// Jquery
import jquery from "jquery";
window.$ = window.jquery = jquery;
// Select2
import "select2";
$.fn.select2.defaults.set("theme", "bootstrap5");
$.fn.select2.defaults.set("width", "100%");
$.fn.select2.defaults.set("selectionCssClass", ":all:");

// Flatpickr
import flatpickr from "flatpickr";
// DualListbox
import DualListbox from "./components/dual-listbox";
// Keenthemes' plugins
window.KTUtil = require("./components/util.js");
window.KTBlockUI = require("./components/blockui.js");
window.KTCookie = require("./components/cookie.js");
window.KTDialer = require("./components/dialer.js");
window.KTEventHandler = require("./components/event-handler.js");
window.KTDrawer = require("./components/drawer.js");
window.KTFeedback = require("./components/feedback.js");
window.KTImageInput = require("./components/image-input.js");
window.KTMenu = require("./components/menu.js");
window.KTPasswordMeter = require("./components/password-meter.js");
window.KTScroll = require("./components/scroll.js");
window.KTScrolltop = require("./components/scrolltop.js");
window.KTSearch = require("./components/search.js");
window.KTStepper = require("./components/stepper.js");
window.KTSticky = require("./components/sticky.js");
window.KTSwapper = require("./components/swapper.js");
window.KTToggle = require("./components/toggle.js");

// Layout base js
window.KTApp = require("./layout/app.js");
window.KTLayoutExplore = require("./layout/explore.js");
window.KTLayoutSearch = require("./layout/search.js");
window.KTLayoutToolbar = require("./layout/toolbar");
