import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
export default class extends Controller {
  static targets = ["item"];
  static classes = ["active"];

  connect() {
    $("#yakult_drinker").select2();
    $("#yakult_drinker").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    $("#participation_route").select2();
    $("#participation_route").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    $("#step_sync_method").select2();
    $("#step_sync_method").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    $("#mets_config").select2();
    $("#mets_config").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    this.steps_sync_change();
    this.appUserPaymentMethodChange();
  }

  itemClick(event) {
    var selectedId = event.params.id;
    this.itemTargets.forEach((element, index) => {
      if (element.dataset.personalInfoMasterIdParam == selectedId) {
        element.classList.add(this.activeClass);
      } else {
        element.classList.remove(this.activeClass);
      }
    });
  }

  steps_sync_change() {
    if ($("#step_sync_method").val() == "n_system") {
      $(".n_system_id_session").removeClass("d-none");
    } else {
      $("#n_system_id").val("");
      $(".n_system_id_session").addClass("d-none");
    }
    if ($("#step_sync_method").val() == "activity_meter") {
      $(".nfc_device_id_session").removeClass("d-none");
    } else {
      $("#nfc_device_id").val("");
      $(".nfc_device_id_session").addClass("d-none");
    }
  }

  appUserPaymentMethodChange(e) {
    if ($("#app_user_bank_info_payment_method_cash").is(":checked")) {
      $(".bank-info-input").addClass("d-none");
    } else {
      $(".bank-info-input").removeClass("d-none");
    }
  }

  saveBankInfo() {
    if ($("#app_user_bank_info_payment_method_cash").is(":checked")) {
      $("#bank_name").val("");
      $("#branch").val("");
      $("#app_user_bank_info_acc_type_ordinary_deposit").prop("checked", true);
      $("#acc_name").val("");
      $("#acc_no").val("");
    }
  }
}
