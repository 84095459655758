'use strict';

// Class definition
var KTDualListbox = function () {
    // Private functions
    var demo4 = function () {
        // Dual Listbox
        var $this = $('#kt_dual_listbox_4');

        // get options
        var options = [];
        $this.children('option').each(function () {
            var value = $(this).val();
            var label = $(this).text();
            options.push({
                text: label,
                value: value
            });
        });

        // init dual listbox
        var dualListBox = new DualListbox($this.get(0), {
            addEvent: function (value) {
                console.log(value);
            },
            removeEvent: function (value) {
                console.log(value);
            },
            availableTitle: '一覧',
            selectedTitle: '選択済み一覧',
            addButtonText: '追加',
            removeButtonText: '削除',
            addAllButtonText: '全追加',
            removeAllButtonText: 'リセット',
            options: options,
        });

        dualListBox.addEventListener("added", function (event) {
            $("#input_screen").removeClass("disabled");
        });

        dualListBox.addEventListener("removed", function (event) {
            if ($(".dual-listbox__selected").children().length == 0) {
                $("#input_screen").addClass("disabled");
            } else {
                $("#input_screen").removeClass("disabled");
            };
        });
        
        $("#add_all").on('click', function (event) {
            if ($(".dual-listbox__selected").children().length == 0) {
                $("#input_screen").addClass("disabled");
            } else {
                $("#input_screen").removeClass("disabled");
            };
        })

        $("#remove_all").on('click', function (event) {
            if ($(".dual-listbox__selected").children().length == 0) {
                $("#input_screen").addClass("disabled");
            } else {
                $("#input_screen").removeClass("disabled");
            };
        })
    };

    return {
        // public functions
        init: function () {
            demo4();
        },
    };
}();

// // Webpack support
if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
    module.exports = KTDualListbox;
}