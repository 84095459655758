import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

// Connects to data-controller="news"
export default class extends Controller {
  static targets = ["item"];
  static classes = ["active"];

  connect() {
    $("#provider_select").select2();
    $('#provider_select').on("select2:select", function (e) {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);

      var data = e.params.data;
      $.ajax({
        url: "/get_subscribers",
        data: {
          sel_provider_id: data.id
        },
        type: "GET",
        dataType: "json",
        success: function (data) {
          if (data) {
            $.each(data.subscriber_list, function (i, e) {
              $('#subscriber_select').append(`<option value=${e[1]}>` + e[0] + '</option>');
            });
            if ($('#subscriber_select option').length == 1) {
              $('.subscriber-select-remark').text("登録したサービス加入者がございません。")
            } else {
              $('.subscriber-select-remark').text("")
            };
          }
        },
        error: function (error) { },
      });
      e.stopImmediatePropagation();
    });

    $('#provider_select').on('select2:unselect', function (e) {
      var data = e.params.data;
      $.ajax({
        url: "/remove_subscribers",
        data: {
          sel_provider_id: data.id
        },
        type: "GET",
        dataType: "json",
        success: function (data) {
          if (data) {
            $.each(data.subscriber_list, function (i, e) {
              $('#subscriber_select').find(`option[value=${e[1]}]`).remove();
            });
            $.each(data.group_list, function (i, e) {
              $('#group_select').find(`option[value=${e[1]}]`).remove();
            });
            if ($("#provider_select :selected").length > 0 && $('#subscriber_select option').length == 1) {
              $('.subscriber-select-remark').text("登録したサービス加入者がございません。")
            } else {
              $('.subscriber-select-remark').text("")
            };
            if ($("#subscriber_select :selected").length > 0 && $('#group_select option').length == 1) {
              $('.group-select-remark').text("登録したグループがございません。")
            } else {
              $('.group-select-remark').text("")
            };
          }
        },
        error: function (error) { },
      });
      e.stopImmediatePropagation();
    });

    $("#subscriber_select").select2();
    $("#subscriber_select").on("select2:select", function (e) {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);

      var data = e.params.data;
      $.ajax({
        url: "/get_groups",
        data: {
          sel_subscriber_id: data.id
        },
        type: "GET",
        dataType: "json",
        success: function (data) {
          if (data) {
            $.each(data.group_list, function (i, e) {
              $('#group_select').append(`<option value=${e[1]}>` + e[0] + '</option>');
            });
            if ($('#group_select option').length == 1) {
              $('.group-select-remark').text("登録したグループがございません。")
            } else {
              $('.group-select-remark').text("")
            };
          }
        },
        error: function (error) { },
      });
      e.stopImmediatePropagation();
    });

    $('#subscriber_select').on('select2:unselect', function (e) {
      var data = e.params.data;
      $.ajax({
        url: "/remove_groups",
        data: {
          sel_subscriber_id: data.id
        },
        type: "GET",
        dataType: "json",
        success: function (data) {
          if (data) {
            $.each(data.group_list, function (i, e) {
              $('#group_select').find(`option[value=${e[1]}]`).remove();
            });
            if ($("#subscriber_select :selected").length > 0 && $('#group_select option').length == 1) {
              $('.group-select-remark').text("登録したグループがございません。")
            } else {
              $('.group-select-remark').text("")
            };
          }
        },
        error: function (error) { },
      });
      e.stopImmediatePropagation();
    });

    $("#group_select").select2();
    $("#group_select").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
    
    if ($("#action_name").val() == "edit" || $("#action_name").val() == "update" || $("#action_name").val() == "create") {
      this.setMulitiSelect();
    }
  }

  itemClick(event) {
    var selectedId = event.params.id;
    this.itemTargets.forEach((element, index) => {
      if (element.dataset.newsIdParam == selectedId) {
        element.classList.add(this.activeClass);
      } else {
        element.classList.remove(this.activeClass);
      }
    });
  }

  newBtnClick() {
    if ($(".news-item").hasClass('active')) {
      $(".news-item").removeClass('active');
    }
  }

  setMulitiSelect() {
    var subscribersLists = JSON.parse($("#subscribers").val());
    var selSubscribersLists = JSON.parse($("#sel_subscribers").val());
    var groupsLists = JSON.parse($("#groups").val()); 
    var selGroupsLists = JSON.parse($("#sel_groups").val()); 
    $.each(subscribersLists, function (i, e) {
      $('#subscriber_select').append(`<option value=${e[1]} ${selSubscribersLists.includes(e[1]) ? 'selected' : ''}>` + e[0] + '</option>');
    });
    $.each(groupsLists, function (i, e) {
      $('#group_select').append(`<option value=${e[1]} ${selGroupsLists.includes(e[1]) ? 'selected' : ''}>` + e[0] + '</option>');
    });
  }
}
