import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="loading"
export default class extends Controller {
  static targets = ["loading_area", "loading_content"];

  connect() {
    this.loading_areaTargets.forEach((la) => {
      la.classList.add("d-none");
    });

    this.loading_contentTarget.classList.remove("blurred");
  }

  showLoading() {
    this.loading_areaTargets.forEach((la) => {
      la.classList.remove("d-none");
    });

    this.loading_contentTarget.classList.add("blurred");
  }
}
