import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="selected-org-hierarchy"
export default class extends Controller {
  connect() {
  }

  setOrgCodeCookieOnClick() {
    // set cookies expires in 2seconds
    var now = new Date();
    now.setTime(now.getTime() + (2 * 1000));
    document.cookie = "sel_provider_code=" + $("#sel_provider_code").val()
      + ';expires=' + now.toUTCString() + ';path=/';
    document.cookie = "sel_subscriber_code=" + $("#sel_subscriber_code").val()
      + ';expires=' + now.toUTCString() + ';path=/';
    document.cookie = "sel_group_code=" + $("#sel_group_code").val()
      + ';expires=' + now.toUTCString() + ';path=/';
    document.cookie = "selected_item=" + $("#selected_item").val()
      + ';expires=' + now.toUTCString() + ';path=/';
    document.cookie = "selected_provider=" + $("#selected_provider").val()
      + ';expires=' + now.toUTCString() + ';path=/';
    document.cookie = "selected_subscriber=" + $("#selected_subscriber").val()
      + ';expires=' + now.toUTCString() + ';path=/';
    document.cookie = "selected_group=" + $("#selected_group").val()
      + ';expires=' + now.toUTCString() + ';path=/';
  }

  groupChangeCancel() {
    // set cookies expires in 2seconds
    var now = new Date();
    now.setTime(now.getTime() + (2 * 1000));
    document.cookie = "selected_item=" + $("#selected_item").val()
      + ';expires=' + now.toUTCString() + ';path=/';
    document.cookie = "sel_provider_code=" + $("#sel_provider_code").val()
      + ';expires=' + now.toUTCString() + ';path=/';
    document.cookie = "sel_subscriber_code=" + $("#cancel_sel_subscriber_code").val()
      + ';expires=' + now.toUTCString() + ';path=/';
    document.cookie = "sel_group_code=" + $("#cancel_sel_group_code").val()
      + ';expires=' + now.toUTCString() + ';path=/';
  }
}
