import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sign-in"
export default class extends Controller {
  connect() {
  }

  tabToSubscriberField(e) {
    if (e.target.value.length == 3) {
      $("#subscriber_layer").focus();
    }
  }

  tabToGroupField(e) {
    if (e.target.value.length == 4) {
      $("#group_layer").focus();
    }
  }

  submit(){
    $("#org_code").val(
      $("#provider_layer").val() + '-' +
      $("#subscriber_layer").val() + '-' + 
      $("#group_layer").val()
    )
  }
}
