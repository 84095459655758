import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];
  static classes = ["active"];
  connect() {}

  itemClick(event) {
    var selectedId = event.params.id;
    this.itemTargets.forEach((element, index) => {
      if (element.dataset.mEventIdParam == selectedId) {
        element.classList.add(this.activeClass);
      } else {
        element.classList.remove(this.activeClass);
      }
    });
  }

  newBtnClick() {
    if ($(".m-event-item").hasClass('active')) {
      $(".m-event-item").removeClass('active');
    }
  }
}
