// import { Japanese } from "flatpickr/dist/l10n/ja";
// /*date of birth date picker*/
// if (document.getElementById("date_of_birth")) {
//   if (document.getElementById("date_of_birth").value) {
//     flatpickr(".birthday_datepicker", {
//       wrap: true,
//       maxDate: "today",
//       locale: Japanese,
//       disableMobile: "true",
//       allowInvalidPreload: true,
//       altInput: true,
//       altFormat: "Y年m月d日",
//     });
//   } else {
//     flatpickr(".birthday_datepicker", {
//       wrap: true,
//       maxDate: "today",
//       locale: Japanese,
//       defaultDate: "1999-04-01",
//       disableMobile: "true",
//       allowInvalidPreload: true,
//       altInput: true,
//       altFormat: "Y年m月d日",
//     });
//   }
// }

// /* m_event point integer */
// $("#point").keyup(function () {
//   this.value = this.value.replace(/[^0-9]/g, "");
// });

$(".m_fee_number").keyup(function () {
  this.value = this.value.replace(/[A-Za-z]/g, "");
});

// /* start_date of m_event */
//  flatpickr(".event_sDate", {
//   wrap: true,
//   minDate: "today",
//   locale: Japanese,
//   disableMobile: "true",
//   allowInvalidPreload: true,
//   altInput: true,
//   altFormat: "Y年m月d日",
// });

// /* end_date of m_event */
//   flatpickr(".event_eDate", {
//   wrap: true,
//   minDate: "today",
//   locale: Japanese,
//   disableMobile: "true",
//   allowInvalidPreload: true,
//   altInput: true,
//   altFormat: "Y年m月d日",
// });

// /*date of payment start date picker*/
// if (document.getElementById("payment_start_date")) {
//   if (document.getElementById("payment_start_date").value) {
//     flatpickr(".payment_start_datepicker", {
//       wrap: true,
//       maxDate: "today",
//       locale: Japanese,
//       disableMobile: "true",
//       allowInvalidPreload: true,
//       altInput: true,
//       altFormat: "Y年m月d日",
//     });
//   } else {
//     flatpickr(".payment_start_datepicker", {
//       wrap: true,
//       maxDate: "today",
//       locale: Japanese,
//       disableMobile: "true",
//       allowInvalidPreload: true,
//       altInput: true,
//       altFormat: "Y年m月d日",
//     });
//   }
// }

// /*group monthly photo post dateime picker*/
// if (document.getElementById("date_from") && document.getElementById("date_to")) {
//   let date_from = flatpickr(".date_from_datepicker", {
//     minDate: "today",
//     locale: Japanese,
//     wrap: true,
//     disableMobile: "true",
//     allowInvalidPreload: true,
//     altInput: true,
//     altFormat: "Y年m月d日",
//     onChange: function (selectedDates, dateStr, instance) {
//       date_to.set('minDate',dateStr);
//     },
//   });

//   let date_to = flatpickr(".date_to_datepicker", {
//     minDate: $('#date_from').attr('value')? $('#date_from').attr('value') : "today",
//     locale: Japanese,
//     wrap: true,
//     disableMobile: "true",
//     allowInvalidPreload: true,
//     altInput: true,
//     altFormat: "Y年m月d日",
//     onChange: function (selectedDates, dateStr, instance) {
//       date_from.set('maxDate',dateStr);
//     },
//   });
// };

// /* password field eye icon click event [start]*/
// $("body").on("click", ".toggle-password", function () {
//   $(this).children().toggleClass("d-none");
//   var password = $("#password");
//   password.attr("type") === "password"
//     ? password.attr("type", "text")
//     : password.attr("type", "password");
// });

// $("body").on("click", ".conf-toggle-password", function () {
//   $(this).children().toggleClass("d-none");
//   var password = $("#password_confirmation");
//   password.attr("type") === "password"
//     ? password.attr("type", "text")
//     : password.attr("type", "password");
// });
// /* password field eye icon click event [end]*/

// /* sys_provider_id select change event [start] */
// $("#sys_provider_id").on("change", function (e) {
//   var sys_provider_id = $(this).val();
//   var provider_layer = $("#provider_layer");
//   var subscriber_layer = $("#subscriber_layer");
//   if (sys_provider_id == "") {
//     provider_layer.val("");
//     subscriber_layer.val("");
//   } else {
//     let previous_organization_id = $("#previous_org_id").val();
//     let previous_provider_layer = $("#previous_provider_layer").val();
//     let previous_subscriber_layer = $("#previous_subscriber_layer").val();
//     if (previous_organization_id != sys_provider_id) {
//       // ajax request
//       $.ajax({
//         url: "/sys_provider_id_change",
//         type: "GET",
//         data: {
//           sys_provider_id: sys_provider_id,
//         },
//         dataType: "json",
//         success: (data) => {
//           provider_layer.val(data.provider_layer);
//           subscriber_layer.val(data.subscriber_layer);
//         },
//       });
//     } else {
//       provider_layer.val(previous_provider_layer);
//       subscriber_layer.val(previous_subscriber_layer);
//     }
//   }
// });
// $("#sys_provider_id").trigger("change");
// /* sys_provider_id select change event [end] */

/* toastr [start]*/
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: false,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "slideDown",
  hideMethod: "slideUp",
};

if ($("#flash_success_title").length) {
  toastr.success(
    $("#flash_success_message").text(),
    $("#flash_success_title").text()
  );
} else if ($("#flash_error_title").length) {
  toastr.error(
    $("#flash_error_message").text(),
    $("#flash_error_title").text()
  );
}

// /* Group Image Show each image */
// const showImage = document.createElement("div");
// showImage.classList.add("showImage");
// document.body.appendChild(showImage);

// const images = document.querySelectorAll("img");
// images.forEach((image) => {
//   image.addEventListener("click", (e) => {
//     showImage.classList.add("active");
//     const img = document.createElement("img");
//     img.src = image.src;
//     while (showImage.firstChild) {
//       showImage.removeChild(showImage.firstChild);
//     }
//     showImage.appendChild(img);
//   });
// });

// showImage.addEventListener("click", (e) => {
//   if (e.target !== e.currentTarget) return;
//   showImage.classList.remove("active");
// });

// Loading blur remove
document.addEventListener("turbo:before-stream-render", (event) => {
  if (event.target.action === "replace") {
    const blurElement = document.getElementsByClassName(
      "d-flex flex-column flex-root bg-light-primary blurred"
    );

    while (blurElement.length > 0) {
      blurElement[0].classList.remove("blurred");
    }

    setTimeout(() => {
      initializeTooltips();
    }, "500");
  }
});

function initializeTooltips() {
  // remove tooltip
  const prevTooltipElements =
    document.getElementsByClassName("tooltip fade show");

  while (prevTooltipElements.length > 0) {
    prevTooltipElements[0].remove();
  }

  // add tooltip
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
}
