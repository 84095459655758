import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="gender-radio-btn"
export default class extends Controller {
  connect() {
    this.rdBtnChange();
  }

  rdBtnChange() {
    const male = document.getElementById('user_gender_male')
    const female = document.getElementById('user_gender_female')
    const other = document.getElementById('user_gender_other_gen')

    if (male.checked) {
      $(male).parent().parent().addClass('active')
    } else {
      $(male).parent().parent().removeClass('active')
    }

    if (female.checked) {
      $(female).parent().parent().addClass('active')
    } else {
      $(female).parent().parent().removeClass('active')
    }

    if (other.checked) {
      $(other).parent().parent().addClass('active')
    } else {
      $(other).parent().parent().removeClass('active')
    }
  }
}
