import { Controller } from "@hotwired/stimulus";
import { Japanese } from "flatpickr/dist/l10n/ja";
export default class extends Controller {
    connect() {
        this.datePicker();

        $("#event").select2();
        $("#event").on("select2:select", function () {
            let event = new Event("change", { bubbles: true }); // fire a native event
            this.dispatchEvent(event);
        });

        /* Event Join Click Event */
        $(".event-join").on("click", function (e) {
            var app_user = $(this);
            var sel_event_id = $("#sel_event_id").val();
            var org_code = $("#org_code").val();
            var sel_event_date = $("#event_date").val();
            var repeat_flg = $("#event_repeat_flg").val();
            $.ajax({
                url: "/event_participations/join_event",
                data: {
                    sel_event_id: sel_event_id,
                    sel_event_date: sel_event_date,
                    org_code: org_code,
                    app_user_code: app_user.attr('id'),
                    event_repeat_flg: repeat_flg
                },
                type: "GET",
                dataType: "json",
                success: function (data) {
                    switch (data.entry_status) {
                        case "blank":
                            app_user.removeClass("btn-info").addClass("btn-danger")
                            app_user.html("-")
                            break;
                        case "attending":
                            app_user.removeClass("btn-danger").addClass("btn-success")
                            app_user.html("参加")
                            break;
                        case "planning_to_attend":
                            app_user.removeClass("btn-success").addClass("btn-info")
                            app_user.html("参加予定")
                            break;
                        default:
                            return;
                    }
                },
                error: function (error) { },
            });
            e.stopImmediatePropagation();
            return false;
        });

        /* Event Code Change Event */
        $(".event-code").on("change", function (e) {
            var sel_group_id = $("#selected_id").val();
            var sel_event_id = $(this).val();
            $.ajax({
                url: "/event_participations/change_event",
                data: {
                    sel_group_id: sel_group_id,
                    sel_event_id: sel_event_id
                },
                type: "GET",
                dataType: "json",
                success: function (data) {
                    if (sel_event_id != "") {
                        window.location = "/event_participations/event_data_input?sel_id=" + sel_group_id + "&event_date=" + data.event_date + "&event_id=" + sel_event_id;
                    } else {
                        window.location = "/event_participations/event_data_input?sel_id=" + sel_group_id;
                    }
                },
                error: function (error) { },
            });
            e.stopImmediatePropagation();
            return false;
        });

        /* Image Delete Click Event */
        $(".img-delete").on("click", function (e) {
            var app_user = $(this);
            var sel_event_id = $("#sel_event_id").val();
            var org_code = $("#org_code").val();
            $.ajax({
                url: "/event_participations/delete_attached_image",
                data: {
                    sel_event_id: sel_event_id,
                    org_code: org_code,
                    app_user_code: app_user.attr('id')
                },
                type: "GET",
                dataType: "json",
                success: function (data) {
                    if (data.img_deleted_flg) {
                        $('.del-btn-' + data.event_entry_id).addClass("d-none");
                        $('.chosen-text' + data.event_entry_id).parent().remove();
                        $('#chosen-file-preview' + data.event_entry_id).val("写真をアップロードしてください。").addClass("text-muted");
                    }
                },
                error: function (error) { },
            });
            e.stopImmediatePropagation();
            return false;
        });
        
    }

    datePicker = function () {
        /* Date of Measurement */
        if (document.getElementById("event_date")) {
            if (document.getElementById("event_date").value) {
                var repeatFlg = repeat_flg
                var eventStartDate = event_start_date
                var eventEndDate = event_end_date
                if (repeatFlg == "not_repeated") {
                    var eventDates = this.getDatesBetween(eventStartDate, eventEndDate)
                    flatpickr(".event_date_datepicker", {
                        wrap: true,
                        locale: Japanese,
                        disableMobile: "true",
                        allowInvalidPreload: true,
                        altInput: true,
                        altFormat: "Y年m月d日",
                        minDate: eventStartDate,
                        maxDate: eventEndDate,
                        // bg color event period in date picker
                        onDayCreate: function (dObj, dStr, fp, dayElem) {
                            if (eventDates.indexOf(+dayElem.dateObj) !== -1) {
                                dayElem.className += " event-date";
                            }
                        }
                    });
                } else if (repeatFlg == "repeated") {
                    var repeatEventDates = repeat_event_dates.slice(1, repeat_event_dates.length-1).split(", ")
                    var eventDates = []
                    for (let i = 0; i < repeatEventDates.length; i++) {
                        eventDates.push(Date.parse(repeatEventDates[i].slice(1, repeatEventDates[i].length - 1)))
                    }
                    if (eventStartDate !== " " && eventEndDate !== " ") {
                        flatpickr(".event_date_datepicker", {
                            wrap: true,
                            locale: Japanese,
                            disableMobile: "true",
                            allowInvalidPreload: true,
                            altInput: true,
                            altFormat: "Y年m月d日",
                            minDate: eventStartDate,
                            maxDate: eventEndDate,
                            // bg color event present dates in date picker
                            onDayCreate: function (dObj, dStr, fp, dayElem) {
                                if (eventDates.indexOf(+dayElem.dateObj) !== -1) {
                                    dayElem.className += " event-date";
                                }
                            }
                        });
                    } else {
                        flatpickr(".event_date_datepicker", {
                            wrap: true,
                            locale: Japanese,
                            disableMobile: "true",
                            allowInvalidPreload: true,
                            altInput: true,
                            altFormat: "Y年m月d日",
                            // bg color event present dates in date picker
                            onDayCreate: function (dObj, dStr, fp, dayElem) {
                                if (eventDates.indexOf(+dayElem.dateObj) !== -1) {
                                    dayElem.className += " event-date";
                                }
                            }
                        });
                    }
                    
                }
            } else {
                flatpickr(".event_date_datepicker", {
                    wrap: true,
                    locale: Japanese,
                    disableMobile: "true",
                    allowInvalidPreload: true,
                    altInput: true,
                    altFormat: "Y年m月d日",
                });
            }
        }

    }

    // Returns array of dates between the two dates
    getDatesBetween(startDate, endDate) {
        const dates = []
        let currentDate = new Date(startDate)
        let lastDate = new Date(endDate)
        while (currentDate <= lastDate) {
            const day = currentDate.toLocaleString('default', { day: '2-digit' });
            const month = currentDate.toLocaleString('default', { month: 'short' });
            const year = currentDate.toLocaleString('default', { year: 'numeric' });
            const eventDate = year + '-' + month + '-' + parseInt(day, 10);
            dates.push(Date.parse(eventDate))
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates
    }

    /* Image Change Event */
    imgChange(event) {
        var idParam = event.target.dataset.idParam;
        var filename = $("#image" + idParam).val().toString().split('\\');
        if ($("#image" + idParam).get(0).files.length != 0) {
            $("#save-btn-" + idParam).removeClass("d-none");
            $("#cancel-btn-" + idParam).removeClass("d-none");
            $(".del-btn-" + idParam).addClass("d-none");
            $('#chosen-file-preview' + idParam).val(filename[filename.length - 1]).addClass("w-250px text-truncate");
        }
    }

    /* Event Date Change Event */
    eventDateChange(event) {
        window.location = "/event_participations/event_data_input?sel_id=" + $("#selected_id").val() + "&event_date=" + event.target.value + "&event_id=" + $("#sel_event_id").val();
    }

}
