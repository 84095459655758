import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];
  static classes = ["active"];
  connect() {
    $("#m_app_display_setting_id").select2();
    $("#m_app_display_setting_id").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
  }

  itemClick(event) {
    var selectedId = event.params.id;
    this.itemTargets.forEach((element, index) => {
      if (element.dataset.groupIdParam == selectedId) {
        element.classList.add(this.activeClass);
      } else {
        element.classList.remove(this.activeClass);
      }
    });
  }
}
