import { Controller } from "@hotwired/stimulus"
import UploadPhoto from "../customs/upload_img"

// Create array on photo count [ new, edit ]
const photoCount = document.getElementById('photo_count');
if(photoCount){
  var selectedPhotosFileNames = new Array(Number(photoCount.value)).fill("img");
}
// Connects to data-controller="multiupload"
export default class extends Controller {
  static targets = ["files"];
  connect() {
   UploadPhoto.init();
  }
  
  removeImage(event) {    
    const removedId = event.target.parentNode.id;   
    const deleteId =  event.target.id;
    const groupPhotoId = $("#group_photo_id").val();  
    const hiddenInput = document.getElementById("monthly_photos"+removedId);
    // Remove element from selectedPhotosFileNames array
    if (selectedPhotosFileNames[removedId-1] == "img"){
      if(groupPhotoId != null) {
      $.ajax({
        url: "/remove_image",
        type: "GET",
        data: {
          image_id: deleteId,
          id: groupPhotoId,
        },
        dataType: "json",
        success: (data) => {
          console.log("Success");
        },
      });    
    }
    }else{
      hiddenInput.value = null;
    }
   
    selectedPhotosFileNames.splice(removedId,1);
    
    // // Remove image from view
    const removedImg = event.target.parentNode.parentNode.parentNode;
    removedImg.remove();   
    this.files = selectedPhotosFileNames;    
  }    
}