"use strict"; 

var apiPostalCode;
// Class definition
var PostalCode = function (element, options) {
  if (typeof element === "undefined" || element === null) {
    return;
  }

  ////////////////////////////
  // ** Private Variables  ** //
  ////////////////////////////
  var body = document.getElementsByTagName("BODY")[0];
  var postalCodeField = $("#postalcode");
  var regionField = $("#region");
  var prefectureField = $("#prefecture");
  var cityField = $("#city");
  var townField = $("#town");
  var regionId = $("#region_id");
  var prefectureId = $("#prefecture_id");
  var addressId = $("#address");
  var dispalyAddressId = $("#display_address");

  ////////////////////////////
  // ** UI Variables  ** //
  ////////////////////////////
  var regionUI = $("#region-section");
  var prefectureUI = $("#prefecture-section");
  var cityUI = $("#city-section");
  var townUI = $("#town-section");
  var addressUI = $("#address-section");

  ////////////////////////////
  // ** Private Methods  ** //
  ////////////////////////////
  var _construct = function () {
    _init();
  };

  var _init = function () {
    // Event Handlers
    postalCodeField.on("keyup", _codeChange);
    addressId.on("keyup", displayAddress);

    hideUiField();

    setPostalCode();
  };

  var _codeChange = function (e) {
    let postal_code = $(this).val();
    if (postal_code.length == 7) {
      setPostalCode(postal_code);
    } else if (postal_code.length < 7 ) {
      regionField.val("");
      prefectureField.val("");
      cityField.val("");
      townField.val("");
      regionId.val("");
      prefectureId.val("");
      addressId.val("");
      dispalyAddressId.val("");
      hideUiField();
      // displayAddress();
    }
  };

  /*generate postal code*/
  var setPostalCode = function (postal_code) {
    postal_code = postal_code || $("#postalcode").val();
    if (postal_code.length == 7) {
      $.ajax({
        type: "get",
        url: "https://maps.googleapis.com/maps/api/geocode/json",
        crossDomain: true,
        dataType: "json",
        data: {
          address: postal_code,
          language: "ja",
          sensor: false,
          components: "country:jp",
          key: "AIzaSyCvM8E0Z3KZb8bwPxx7pBSaAVW3NoBWSNQ",
        },
        success: function (resp) {
          if (resp.status == "OK") {
            let address_data = resp.results[0].address_components;
            if (address_data.length > 1) {
              apiPostalCode = resp.results[0].formatted_address.split(" ")[1];
              $.ajax({
                url:
                  "/get_region_name_by_prefecturename/" +
                  address_data[address_data.length - 2]["long_name"],
                success: function (data) {
                  regionField.val(data.region_name);
                  let address_size = address_data.length;
                  if(address_size <= 4){
                    prefectureField.val(
                      address_data[address_data.length - 2]["long_name"]
                    );
                   cityField.val(
                    address_data[address_data.length - 3]["long_name"]
                   );
                   } else {                   
                    prefectureField.val(
                      address_data[address_data.length - 2]["long_name"]
                    );
                    cityField.val(
                      address_data[address_data.length - 3]["long_name"]
                    );
                    townField.val(
                      address_data[address_data.length - 4]["long_name"]
                    );
                  }
                  regionId.val(data.region_id);
                  prefectureId.val(data.prefecture_id);
                  displayAddress();
                  showUiField();
                },
              });
            } else {
              // alert("日本国内の郵便番号だけ入力してください。");
              regionField.val("");
              prefectureField.val("");
              cityField.val("");
              townField.val("");
              regionId.val("");
              prefectureId.val("");
              displayAddress();
              hideUiField();
              return false;
            }
          } else {
            regionField.val("");
            prefectureField.val("");
            cityField.val("");
            townField.val("");
            regionId.val("");
            prefectureId.val("");
            displayAddress();
            hideUiField();
            return false;
          }
        },
        error: function (error) {
          console.log(error);
        },
      });
    }
  };

  /*get dispaly address*/
  var displayAddress = function () {
    let strText;
    let postal_code = apiPostalCode || "";
    let prefecture_name = prefectureField.val() || "";
    let city_name = cityField.val() || "";
    let town_name = townField.val() || "";
    let address = addressId.val() || "";
    strText =
      postal_code +
      " " +
      prefecture_name +
      " " +
      city_name +
      " " +
      town_name +
      " " +
      address;
    dispalyAddressId.val(strText);
  };

  var hideUiField = function () {
    regionUI.fadeOut();
    prefectureUI.fadeOut();
    cityUI.fadeOut();
    townUI.fadeOut();
    addressUI.fadeOut();
  };

  var showUiField = function () {
    regionUI.fadeIn();
    prefectureUI.fadeIn();
    cityUI.fadeIn();
    townUI.fadeIn();
    addressUI.fadeIn();
  };

  // Construct Class
  _construct();
};

// Create instances
PostalCode.createInstances = function (selector = '[data-postal-code="true"]') {
  var body = document.getElementsByTagName("BODY")[0];

  // Initialize Menus
  var elements = body.querySelectorAll(selector);
  var pCode;

  if (elements && elements.length > 0) {
    for (var i = 0, len = elements.length; i < len; i++) {
      pCode = new PostalCode(elements[i]);
    }
  }
};

// Global initialization
PostalCode.init = function () {
  PostalCode.createInstances();
};

// On document ready
if (document.readyState === "loading") {
  //    document.addEventListener('DOMContentLoaded', PostalCode.init);
  document.addEventListener("turbolinks:load", function () {
    PostalCode.init();
  });
} else {
  PostalCode.init();
}

// Webpack support
if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
  module.exports = PostalCode;
}
